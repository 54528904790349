/* eslint no-console:0 */

// Use Rails-UJS
const Rails = require("@rails/ujs")
window.Rails = Rails
Rails.start()

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// Import and register all TailwindCSS Components
import { Dropdown } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)

// Set up I18n-js
import { i18n } from "./i18n";
window.i18n = i18n

// Set up Hotwired and Turbo
import * as Turbo from "@hotwired/turbo"
window.Turbo = Turbo

// Register the Service Worker
if (navigator.serviceWorker) {
  navigator.serviceWorker.register('/serviceworker.js', { scope: './' })
    .catch(function (err) {
      console.warn('Service Worker registration failed: ', err)
    })
}
