import { Controller } from "@hotwired/stimulus"

let intervalId = ""

// Refresh specific logic
export default class extends Controller {
  connect () {
    const refreshInterval = this.element.dataset.refreshInterval || 3

    intervalId = setInterval(this.reloadPage.bind(this), refreshInterval * 1000)
  }

  disconnect () {
    clearInterval(intervalId)
  }

  reloadPage () {
    if (this.element.dataset.refreshEnabled === "true") {
      window.location.reload()
    } else {
    }
  }
}
